<!-- Version: 1.0 -->
<template>
  <div>
    <div class="eh-listbox-list">
      <!-- Fejléc gomb -->
      <div
        v-if="hasListHeaderButton && listHeaderButtonTitle"
        class="eh-listbox-list-header"
        @click="$emit('headerButtonClick')"
        href="#"
      >
        {{ listHeaderButtonTitle }}
      </div>
      <!-- Fejléc -->
      <div v-else-if="listHeaderTitle" class="eh-listbox-list-header">
        {{ listHeaderTitle }}
      </div>
      <!-- Nincs elem a listában -->
      <div
        class="eh-listbox-list-item"
        v-if="!items || (items && items.length == 0)"
      >
        {{ emptyListText }}
      </div>
      <!-- Lista -->
      <div
        v-for="(item, index) in items"
        :key="index"
        class="eh-listbox-list-item"
        :class="isSelected(item) ? 'eh-listbox-list-item-active' : ''"
        href="#"
        @click="selectItem(item)"
      >
        <slot name="default" v-bind="item">{{ item }}</slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ListBox",
  props: {
    items: Array,
    hasListHeaderButton: Boolean,
    listHeaderButtonTitle: String,
    listHeaderTitle: String,
    emptyListText: String,
    removeSelection: Boolean,
    selectedItem: Object,
  },
  data() {
    return {
      selectedItemFromList: null,
    };
  },
  watch: {
    selectedItem(input) {
      if (!this.removeSelection) {
        this.selectedItemFromList = input;
      }
    },
    removeSelection(input) {
      if (input) {
        this.selectedItemFromList = null;
      }
    },
  },
  methods: {
    selectItem(item) {
      this.$emit("selectingItem", item);
      this.selectedItemFromList = item;
      this.$emit("removeSelection:update", false);
    },
    isSelected(item) {
      if (this.selectedItemFromList) {
        return this.$deepObjectCompare(this.selectedItemFromList, item);
      }
      return false;
    },
  },
};
</script>
